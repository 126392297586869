import React from "react"
import { Helmet } from "react-helmet"
import { graphql, withPrefix } from "gatsby"
import HubspotSignupForm, {
  HubspotReportSignupForm,
  HubspotReportForm,
} from "../components/Hubspot"
import Layout from "../components/Layout"
import PortableText from "@sanity/block-content-to-react"

import "../css/blog.scss"

type SanityImage = {
  asset: {
    url: string
  }
  alt?: string
}

type BlogPostData = {
  id: string
  title: string
  mainImage?: SanityImage
  socialImage?: SanityImage
  authorName: string
  authorPhoto?: string
  author?: any
  publishedAt: string
  content: any
  slug: { current: string }
  postcategory: string
  excerpt: string
  metaKeywords: string
  metaDescription: string
}

type BlogPostProps = {
  data: {
    allSanityBlogPost: {
      nodes: BlogPostData[]
    }
  }
  location: {
    pathname: string
  }
}

function BlogPost({ data, location }: BlogPostProps) {
  const slug = location.pathname.split("/blog/")[1]?.replace("/", "")

  const post = data.allSanityBlogPost.nodes.find(
    (node) => node.slug.current === slug
  )

  if (!post) {
    return (
      <Layout>
        <h1 className="xl---padding-12---1-col">Blog post not found</h1>
      </Layout>
    )
  }

  const { title, mainImage, socialImage, publishedAt, content, author, postcategory, excerpt, metaKeywords, metaDescription } = post

  const defaultImage = "share_preview.png"
  const ogImage = `${
      ((socialImage ?? mainImage)?.asset?.url
      ?? defaultImage)
    }`

  return (
    <Layout className={`blog-page`} hideEmbellishment>
      <Helmet>
        <title>{title}</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta property="og:url" content={withPrefix(post.slug.current)} />
        <meta property="og:image" content={ogImage} />
        <meta property="twitter:image" content={ogImage} />
        <meta property="og:description" content={metaDescription ?? excerpt} />
        <meta property="og:keywords" content={metaKeywords ?? "thesis, blog"} />
      </Helmet>
      <Section1 title={title} />
      <Section2
        title={title}
        socialImage={socialImage}
        mainImage={mainImage}
        date={publishedAt}
        content={content}
        authorName={author?.fullName ?? "Thesis"}
        authorPhoto={author?.photo?.asset?.url}
        author={author}
        postcategory={postcategory}
      />
    </Layout>
  )
}

function Section1({ title }: { title: string }) {
  return (
    <h1 className="h1 text---white" itemProp="title">
      {title}
    </h1>
  )
}

type Section2Props = {
  title: string
  mainImage?: SanityImage
  socialImage?: SanityImage
  date: string
  author?: {
    fullName: string
    photo?: {
      asset: {
        url: string
      };
    };
    bio?: string
  }[]
  postcategory: string
  content: any
}

function Section2({ mainImage, date, content, author = [], postcategory }: Section2Props) {
  const categoryNames = postcategory
    ? postcategory.map((category: { name: string }) => category.name).join(", ")
    : "";
  const reportCategory = categoryNames.includes("report");
  return (
    <article className="section">
      <header className="xl---padding-12---1-col md---block byline">
        {author.map((auth, index) => (
          <div key={index} className="author-info">
            <p
              className="author"
              itemScope
              itemProp="author"
              itemType="https://schema.org/Person"
            >
            {auth.photo?.asset?.url && (
              <img
                src={auth.photo.asset.url}
                alt={`${auth.fullName}'s photo`}
                className="photo"
              />
            )}
              <span itemProp="name">{auth.fullName}</span>
            </p>
          </div>
        ))}
        <p className="date" itemProp="pubdate">
          {date}
        </p>
      </header>
      {mainImage ? (
        <img className="header-image" src={mainImage.asset.url} alt={mainImage.alt ?? "Featured image"} />
      ) : (
        <></>
      )}
      {typeof content === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: content }} className="xl---padding-12---1-col spacing---lg md---block article-body"/>
        ) : (
          <PortableText blocks={content} className="xl---padding-12---1-col spacing---lg md---block article-body" />
       )}
      <a
        id="floating-return-to-top"
        href="javascript:window.scroll({ top: 0, left: 0, behavior: 'smooth' })"
      >
        🔝
      </a>
      <div id="floating-signup">
        <HubspotSignupForm />
      </div>
      {reportCategory ? (
        <>
        <div id="report-floating-signup">
          <HubspotReportSignupForm />
        </div>
        </>
        ) : ('')
      }
      <footer>
        {reportCategory ? (
          <HubspotReportForm />
        ) : (
          <HubspotSignupForm />
        )}
      </footer>
    </article>
  )
}

export const query = graphql`
  query AllBlogPosts {
    allSanityBlogPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        title
        excerpt
        metaKeywords
        metaDescription
        postcategory {
          name
        }
        author {
          fullName
          photo {
            asset {
              url
            }
          }
        }
        mainImage {
          asset {
            altText
            url
          }
        }
        socialImage {
          asset {
            url
          }
        }
        publishedAt(formatString: "MMMM DD, YYYY")
        content: _rawContent(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
      }
    }
  }
`

export default BlogPost
