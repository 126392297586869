import React, { FC } from "react"
import { withPrefix } from "gatsby"

const ThesisStateBitcoin2024: FC = () => (
  <>
  <div className="state-banner-desktop" aria-label="State of Bitcoin 2024">
    <a href="/blog/state-of-bitcoin-2024/" style={{position: "relative", right: "0px"}} className="state-btc-banner">
      <img src={withPrefix("/images/state-btc-2024-full.png")} alt="thesis-mezo" style={{width: "100%"}}/>
    </a>
  </div>
  <div className="state-banner-mobile" aria-label="State of Bitcoin 2024">
    <a href="/blog/state-of-bitcoin-2024/" style={{position: "relative", right: "0px"}} className="state-btc-banner">
      <img src={withPrefix("/images/state-btc-2024-mobile.png")} alt="thesis-mezo" style={{width: "100%"}}/>
    </a>
  </div>
  </>
)

export default ThesisStateBitcoin2024
