import React, { useState, useEffect } from "react"
import ReactModal from "react-modal"
import axios from "axios"

export default function HubspotSignupForm() {
  const [email, setEmail] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const portalId = "46383448"
    const formId = "5b3559bf-18ed-4bd2-9ace-4a5879c8d5d1"

    const data = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    }

    try {
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (response.status === 200) {
        setMessage("Thanks for subscribing!")
      } else {
        setMessage("Oops, it didn't work!")
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 409) {
          setMessage("Looks like you're already subscribed!")
        } else {
          setMessage("Oops, it didn't work!")
        }
      } else {
        setMessage("An unexpected error occurred.")
      }
    }
  }

  return (
    <div>
      <h3>Sign up for the Thesis* newsletter</h3>
      <p>
        You&aposll receive blog posts about company building, crypto, and more
        in your inbox, about twice per month.
      </p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          Enter your email:
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: "1em" }}
          />
        </label>
        <button
          type="submit"
          style={{ background: "black", color: "white", padding: "1em" }}
        >
          Subscribe
        </button>
        {message && <p style={{ padding: "1em 0" }}>*{message}</p>}
      </form>
    </div>
  )
}

export function HubspotReportForm() {
  const [email, setEmail] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const portalId = "46383448"
    const formId = "32bd93ca-7e94-4dcc-85ea-1394a970bf97"

    const data = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    }

    try {
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (response.status === 200) {
        setMessage("Thanks for signing up! You'll recieve a link to the report shortly")
      } else {
        setMessage("Oops, it didn't work!")
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 409) {
          setMessage("Looks like you're already subscribed!")
        } else {
          setMessage("Oops, it didn't work!")
        }
      } else {
        setMessage("An unexpected error occurred.")
      }
    }
  }

  return (
    <div>
      <h3>Download the full report</h3>
      <p>
      Submit your email and receive the full report as well as other
      updates from Thesis*
      </p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          Enter your email:
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ padding: "1em" }}
          />
        </label>
        <button
          type="submit"
          id="report-signup"
          style={{ background: "black", color: "white", padding: "1em" }}
        >
          Get the report
        </button>
        {message && <p style={{ padding: "1em 0" }}>*{message}</p>}
      </form>
    </div>
  )
}

export function HubspotReportSignupForm() {
  const [email, setEmail] = useState<string>("")
  const [message, setMessage] = useState<string>("")
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const portalId = "46383448"
    const formId = "32bd93ca-7e94-4dcc-85ea-1394a970bf97"

    const data = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    }

    try {
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (response.status === 200) {
        setMessage("Thanks for signing up! You'll recieve a link to the report shortly")
      } else {
        setMessage("Oops, it didn't work!")
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 409) {
          setMessage("Looks like you're already subscribed!")
        } else {
          setMessage("Oops, it didn't work!")
        }
      } else {
        setMessage("An unexpected error occurred.")
      }
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? "hidden" : "auto"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isModalOpen])

  return (
    <>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Report Signup Modal"
        style={{
          content: {
            padding: "2em",
            maxWidth: "500px",
            maxHeight: "450px",
            margin: "auto",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 9999,
          },
        }}
      >
        <div>
          <h3>Download the full report</h3>
          <p>
            Submit your email and receive the full report as well as other
            updates from Thesis*
          </p>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">
              Enter your email:
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ padding: "1em", width: "100%" }}
              />
            </label>
            <button
              type="submit"
              id="report-signup"
              style={{
                background: "black",
                color: "white",
                padding: "1em",
                marginTop: "1em",
              }}
            >
              Get the report
            </button>
            {message && <p style={{ padding: "1em 0" }}>*{message}</p>}
          </form>
          <div style={{ marginTop: "20px" }}>
            <a onClick={closeModal}>Continue Reading →</a>
          </div>
        </div>
      </ReactModal>
    </>
  )
}
