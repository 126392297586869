exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bitcoinfi-weekly-tsx": () => import("./../../../src/pages/bitcoinfi-weekly.tsx" /* webpackChunkName: "component---src-pages-bitcoinfi-weekly-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-headlines-tsx": () => import("./../../../src/pages/headlines.tsx" /* webpackChunkName: "component---src-pages-headlines-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-contactus-page-tsx": () => import("./../../../src/templates/contactus-page.tsx" /* webpackChunkName: "component---src-templates-contactus-page-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../../src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-jobs-page-tsx": () => import("./../../../src/templates/jobs-page.tsx" /* webpackChunkName: "component---src-templates-jobs-page-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/news-page.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-ourwork-page-tsx": () => import("./../../../src/templates/ourwork-page.tsx" /* webpackChunkName: "component---src-templates-ourwork-page-tsx" */)
}

